import Image from 'next/image'
import { type FC, type ReactEventHandler, memo } from 'react'
import styled from 'styled-components'

import DeprecatedXIcon from 'assets/svg/app/deprecated-x.svg'
import { FlexDivCentered } from 'components/layout/flex'
import { SYNTH_ICONS } from 'utils/icons'

export type CurrencyIconProps = {
	currencyKey: string
	className?: string
	width?: number
	height?: number
	isDeprecated?: boolean
	style?: any
	url?: string
	onLoad?: ReactEventHandler<HTMLImageElement>
	priority?: boolean
}

const CurrencyIconContainer: FC<CurrencyIconProps> = memo(({ className, ...props }) => (
	<Container className={className}>
		<CurrencyIcon {...props} />
		{!props.isDeprecated ? null : (
			<DeprecatedXIconContainer>
				<DeprecatedXIcon />
			</DeprecatedXIconContainer>
		)}
	</Container>
))

const CurrencyIcon: FC<CurrencyIconProps> = memo(({ currencyKey, isDeprecated, ...rest }) => {
	const props = { width: 26, height: 26, alt: currencyKey, ...rest }
	const src = SYNTH_ICONS[currencyKey]

	if (src) {
		return (
			<Image
				priority
				src={src}
				{...props}
				className={`${currencyKey !== 'KWENTA' && 'synth-icon'}`}
			/>
		)
	} else {
		return (
			<Placeholder $isDeprecated={isDeprecated} {...props}>
				{currencyKey?.substring(0, 4)}
			</Placeholder>
		)
	}
})

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	.synth-icon {
		filter: invert(${(props) => props.theme.colors.selectedTheme.imageInvert.value});
	}
`

const DeprecatedXIconContainer = styled.div`
	position: absolute;
	right: -3px;
	bottom: -3px;
`

const Placeholder = styled(FlexDivCentered)<{
	$isDeprecated?: boolean
	height?: string | number
	width?: string | number
}>`
	border-radius: 100%;
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	border: 1px solid
		${(props) =>
			props.$isDeprecated
				? props.theme.colors.red
				: props.theme.colors.selectedTheme.button.text.primary};
	font-size: 7px;
	font-weight: 700;
	justify-content: center;
	margin: 0 auto;
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
`

export default CurrencyIconContainer
