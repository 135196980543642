import { createSelector } from '@reduxjs/toolkit'
import { isAddress } from 'viem'
import { getAddress } from 'viem'

import { INITIAL_TRADER_PROFILE } from 'sections/dashboard/Leaderboard/constants'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { selectReferralNft } from 'state/referrals/selectors'
import type { RootState } from 'state/store'
import { FetchStatus } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import { providerIsCrossMargin } from 'utils/futures'

export const selectSelectedTimeframe = (state: RootState) => state.stats.selectedTimeframe

export const selectMinTimestamp = createSelector(
	(state: RootState) => state.stats.selectedTimeframe,
	(selectedTimeframe) => {
		if (selectedTimeframe === 'MAX') return 0

		const timeframeSeconds =
			selectedTimeframe === '1M'
				? 60 * 60 * 24 * 30
				: selectedTimeframe === '1Y'
					? 60 * 60 * 24 * 365
					: 0
		const currentTimestamp = new Date().getTime() / 1000
		return Math.floor(currentTimestamp - timeframeSeconds)
	}
)

const selectLeaderboardSearchTerm = (state: RootState) => state.stats.leaderboardSearchTerm

export const selectValidSearchTerm = createSelector(selectLeaderboardSearchTerm, (searchTerm) => {
	return searchTerm && isAddress(searchTerm) ? searchTerm : undefined
})

const selectV3Leaderboard = (state: RootState) => state.stats.leaderboard.v3

const selectV2Leaderboard = (state: RootState) => state.stats.leaderboard.v2

export const selectLeaderboard = createSelector(
	selectV2Leaderboard,
	selectV3Leaderboard,
	selectPerpsProvider,
	(v2, v3, type) => {
		return providerIsCrossMargin(type) ? v3 : v2
	}
)

export const selectLeaderboardLoading = createSelector(
	(state: RootState) => state.stats.queryStatuses,
	(queryStatuses) => {
		return queryStatuses.leaderboard.status === FetchStatus.Loading
	}
)

export const selectWalletStatsProfile = createSelector(
	selectWallet,
	selectLeaderboard,
	selectReferralNft,
	(wallet, leaderboardData, tier) => {
		const tierLevel = tier > 0 ? String(tier + 1) : '0'
		const user = leaderboardData.all.filter(({ trader }) => getAddress(trader) === wallet)
		return user.length > 0
			? { ...user[0], tier: tierLevel }
			: { ...INITIAL_TRADER_PROFILE, trader: wallet ?? '' }
	}
)
