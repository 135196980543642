import type { FC } from 'react'
import styled from 'styled-components'

import GitbookIcon from 'assets/svg/gitbook.svg'
import DiscordIcon from 'assets/svg/social/discord-2.svg'
import TwitterIcon from 'assets/svg/social/twitter-2.svg'
import { EXTERNAL_LINKS } from 'constants/links'

type LinksProps = {
	isMobile?: boolean
}

const Links: FC<LinksProps> = ({ isMobile }) => (
	<LinkContainer $isMobile={isMobile}>
		<a href={EXTERNAL_LINKS.Social.Twitter} target="_blank" rel="noreferrer">
			<TwitterIcon height={25} />
		</a>
		<a href={EXTERNAL_LINKS.Social.Discord} target="_blank" rel="noreferrer">
			<DiscordIcon height={25} />
		</a>
		<a href={EXTERNAL_LINKS.Docs.DocsRoot} target="_blank" rel="noreferrer">
			<GitbookIcon height={25} />
		</a>
	</LinkContainer>
)

const LinkContainer = styled.div<{ $isMobile?: boolean }>`
	display: flex;
	gap: 30px;
	align-items: center;
	padding: 0 20px;
	margin-bottom: 30px;
	
`

export default Links
