import { type FuturesCumulativeStats, SnxV2NetworkIds } from '@kwenta/sdk/types'
import { useQuery } from '@tanstack/react-query'

import QUERY_KEYS from 'constants/queryKeys'
import ROUTES from 'constants/routes'
import Connector from 'containers/Connector'
import logError from 'utils/logError'

import sdk from 'state/sdk'

const useGetFuturesCumulativeStats = () => {
	const { network } = Connector.useContainer()
	const homepage = window.location.pathname === ROUTES.Home.Root

	return useQuery<FuturesCumulativeStats | null>({
		queryKey: QUERY_KEYS.Futures.TotalTrades(network?.id as SnxV2NetworkIds),
		queryFn: async () => {
			try {
				return await sdk.snxPerpsV2.getFuturesCumulativeStats(SnxV2NetworkIds.OPTIMISM_MAINNET)
			} catch (e) {
				logError(e)
				return null
			}
		},
		enabled: homepage,
	})
}

export default useGetFuturesCumulativeStats
