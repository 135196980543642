import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Alchemy from 'assets/svg/partners/landing/alchemy.svg'
import Biconomy from 'assets/svg/partners/landing/biconomy.svg'
import ChainLinkLogo from 'assets/svg/partners/landing/chainlink.svg'
import OptimismLogo from 'assets/svg/partners/landing/optimism.svg'
import PythLogo from 'assets/svg/partners/landing/pyth.svg'
import Socket from 'assets/svg/partners/landing/socket.svg'
import DiscordLogo from 'assets/svg/social/landing/discord.svg'
import XLogo from 'assets/svg/social/landing/x-icon.svg'
import { DesktopOnlyView } from 'components/Media'
import { FlexDivRowCentered } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'
import { ExternalLink } from 'styles/common'

const Footer = () => {
	const { t } = useTranslation()
	return (
		<DesktopOnlyView>
			<Container>
				<Rights>{t('homepage.footer.copyright')}</Rights>
				<Partners>
					<Biconomy />
					<OptimismLogo />
					<ChainLinkLogo />
					<Socket />
					<PythLogo />
					<Alchemy />
				</Partners>
				<Socials>
					<ExternalLink href={EXTERNAL_LINKS.Social.Twitter}>
						<XLogo width="30px" />
					</ExternalLink>
					<ExternalLink href={EXTERNAL_LINKS.Social.Discord}>
						<DiscordLogo width="30px" />
					</ExternalLink>
				</Socials>
			</Container>
		</DesktopOnlyView>
	)
}

const Container = styled(FlexDivRowCentered)`
	background: ${(props) => props.theme.colors.selectedTheme.background};
	height: 60px;
	padding: 20px 40px;
`
const Rights = styled(FlexDivRowCentered)`
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.tertiary};
`
const Partners = styled(FlexDivRowCentered)`
	flex: 1;
	justify-content: center;
	gap: 60px;
	padding: 0 40px;
`
const Socials = styled(FlexDivRowCentered)`
	width: 120px;
	justify-content: flex-end;
	gap: 10px;
`

export default Footer
