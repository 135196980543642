import type { PerpsProvider } from '@kwenta/sdk/types'
import ROUTES from 'constants/routes'
import { useCallback } from 'react'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { selectOpenPositionAsset } from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'

export function useMarketPageRoute() {
	const selectedPerpsProvider = useAppSelector(selectPerpsProvider)
	const marketAsset = useAppSelector(selectOpenPositionAsset)

	return useCallback(
		(perpsProvider?: PerpsProvider) =>
			ROUTES.Markets.Home(perpsProvider ?? selectedPerpsProvider, marketAsset),
		[marketAsset, selectedPerpsProvider]
	)
}
