import { createSlice } from '@reduxjs/toolkit'

import { FetchStatus } from 'state/types'

import { fetchBalancesAndAllowances } from './actions'
import type { BalancesState } from './types'

const ZERO_BALANCES = {
	balancesByChain: {},
}

export const BALANCES_INITIAL_STATE: BalancesState = {
	status: FetchStatus.Idle,
	error: undefined,
	...ZERO_BALANCES,
}

const balancesSlice = createSlice({
	name: 'balances',
	initialState: BALANCES_INITIAL_STATE,
	reducers: {
		clearBalances: (state) => {
			state.balancesByChain = {}
			state.error = undefined
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchBalancesAndAllowances.fulfilled, (state, action) => {
			if (action.payload) {
				const { wallet, balancesAndAllowances } = action.payload
				state.balancesByChain[wallet] = balancesAndAllowances
			}
		})
	},
})

export default balancesSlice.reducer
