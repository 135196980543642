import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from 'state/store'

export const selectSignerWallet = (state: RootState) => state.wallet.walletAddress.signerWallet

export const selectWatcherWallet = (state: RootState) => state.wallet.walletAddress.watcherWallet

export const selectSubaccountWallet = (state: RootState) =>
	state.wallet.walletAddress.subAccountWallet

const selectIsSignerMode = (state: RootState) =>
	state.wallet.walletAddress.selectedType === 'signer'

export const selectIsWatcherMode = (state: RootState) =>
	state.wallet.walletAddress.selectedType === 'watcher'

export const selectIsSubAccountMode = (state: RootState) =>
	state.wallet.walletAddress.selectedType === 'sub_account'

export const selectEmailAddress = (state: RootState) => state.wallet.emailAddress

export const selectWallet = createSelector(
	selectIsSignerMode,
	selectIsSubAccountMode,
	selectSignerWallet,
	selectWatcherWallet,
	selectSubaccountWallet,
	(isSignerMode, isSubAccountMode, signerWallet, watcherWallet, subAccountWallet) => {
		if (isSignerMode) return signerWallet
		if (isSubAccountMode) return subAccountWallet
		return watcherWallet
	}
)

export const selectAbstractionAddress = (state: RootState) => state.wallet.abstractionAddress

export const selectSignerNetwork = (state: RootState) => state.wallet.walletNetworkId
