export const PROD_HOSTNAME = 'exchange.synthetix.io'
export const PROD_REFFERAL_URL = 'exchange.synthetix.io/market'

export const EXTERNAL_LINKS = {
	Social: {
		Twitter: 'https://x.com/SNX_Exchange',
		Discord: 'https://discord.com/invite/Synthetix',
		GitHub: 'https://github.com/kwenta/kwenta',
	},
	Docs: {
		DocsRoot: 'https://docs.synthetix.io/trade/synthetix-exchange',
		Staking: 'https://docs.kwenta.io/kwenta-token/staking-kwenta',
		SnxTradingIncentivesOnBase:
			'https://mirror.xyz/kwenta.eth/WSfh-XE1CHPlJCxSZSa4yCZsj7H-9fxn8LkBjeNEzDg',
		RewardsGuide: 'https://mirror.xyz/kwenta.eth/-WuyXleDYy80mo5oQH_8MSyM4BLYKvXZ3-VLJFNYB30',
		Referrals: 'https://docs.kwenta.io/using-kwenta/referral',
		DelegationTrading: 'https://docs.kwenta.io/using-kwenta/delegation',
		TermsOfService: 'https://gov.kwenta.eth.limo/sections/7/#terms-of-service',
		PrivacyPolicy: 'https://gov.kwenta.eth.limo/sections/7/#terms-of-service',
		KwentaMigration: 'https://kwenta-migration.synthetix.io/',
	},
	Referrals: {
		BoostNFT: 'https://opensea.io/assets/optimism/0xD3B8876073949D790AB718CAD21d9326a3adA60f',
	},
}
