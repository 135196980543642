import { PointsLevel } from '@kwenta/sdk/types'
import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_QUERY_STATUS, LOADING_STATUS, SUCCESS_STATUS } from 'state/constants'
import { FetchStatus } from 'state/types'

import { fetchPoints } from './actions'
import type { PointsState } from './types'

const STATS_INITIAL_STATE: PointsState = {
	queryStatuses: {
		points: DEFAULT_QUERY_STATUS,
		seasons: DEFAULT_QUERY_STATUS,
		levels: DEFAULT_QUERY_STATUS,
	},
	total: 0,
	level: PointsLevel.NPC_I,
}

const pointsSlice = createSlice({
	name: 'stats',
	initialState: STATS_INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPoints.pending, (state) => {
			state.queryStatuses.points = LOADING_STATUS
		})
		builder.addCase(fetchPoints.rejected, (state) => {
			state.queryStatuses.points = {
				status: FetchStatus.Error,
				error: 'Failed to fetch points',
			}
		})
		builder.addCase(fetchPoints.fulfilled, (state, action) => {
			state.queryStatuses.points = SUCCESS_STATUS
			state.total = action.payload.total / 1e18
			state.level = action.payload.user.level
			state.ens = action.payload.user.ens?.name
		})
	},
})

export default pointsSlice.reducer
