import { type FC, type ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'

import Badge from './Badge'

type MarketBadgeProps = {
	fallbackComponent?: ReactElement
	isFuturesMarketNewlyListed?: boolean
}

const MarketBadge: FC<MarketBadgeProps> = memo(
	({ fallbackComponent, isFuturesMarketNewlyListed }) => {
		const { t } = useTranslation()

		if (isFuturesMarketNewlyListed) {
			return <Badge color="green">{t('futures.market.state.new')}</Badge>
		}

		return fallbackComponent || null
	}
)

export default MarketBadge
