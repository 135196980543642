import { type FC, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { FlexDiv } from 'components/layout/flex'

import TabButton from './TabButton'

type ItemProps = {
	name: string
	label: string
	badge?: number
	active: boolean
	disabled?: boolean
	onClick: () => any
}

type Props = {
	options: ItemProps[]
	noRightBorder?: boolean
	noBorders?: boolean
	showIndicator?: boolean
}
const UnderlineTabs: FC<Props> = ({
	options,
	noRightBorder = false,
	noBorders = false,
	showIndicator = true,
	...props
}) => {
	const tabLeftRef = useRef<HTMLDivElement | null>(null)
	const [refOptions, setRefOptions] = useState<ItemProps[]>()
	const [activeTabWidth, setActiveTabWidth] = useState<number>(0)
	const [activeTabLeft, setActiveTabLeft] = useState<number>(0)

	useEffect(() => {
		if (tabLeftRef.current) {
			setRefOptions(options)
		}
	}, [options])

	return (
		<TabLeft ref={tabLeftRef} $noRightBorder={noRightBorder} $noBorders={noBorders} {...props}>
			{refOptions?.map(({ name, label, badge, active, disabled, onClick }) => (
				<TabButtonContainer key={name}>
					<TabButton
						innerRef={(el: HTMLDivElement) => {
							if (el && active && tabLeftRef.current) {
								setActiveTabWidth(el.getBoundingClientRect().width)
								setActiveTabLeft(
									el.getBoundingClientRect().left - tabLeftRef.current.getBoundingClientRect().left
								)
							}
						}}
						inline
						title={label}
						badgeCount={badge}
						active={active}
						disabled={disabled}
						onClick={onClick}
					/>
				</TabButtonContainer>
			))}
			{showIndicator && <TabIndicator width={activeTabWidth} left={activeTabLeft} />}
		</TabLeft>
	)
}

const TabButtonContainer = styled(FlexDiv)`
	padding: 0 12px;
`

const TabLeft = styled.div<{ $noRightBorder: boolean; $noBorders: boolean }>`
	position: relative;
	display: flex;
	justify-content: left;
	align-items: center;
	& > div {
		border-right: ${(props) => props.theme.colors.selectedTheme.border};
	}
	${(props) =>
		props.$noRightBorder &&
		css`
			> div:nth-child(2) {
				border-right: none;
			}
		`}
	${(props) =>
		props.$noBorders &&
		css`
			> div {
				border: none;
			}
		`}}
	& > div:nth-last-child(2) {
		border-right: none;
	}
`

const TabIndicator = styled.div<{ width: number; left: number }>`
	position: absolute;
	bottom: 0;
	height: 2px;
	width: ${({ width }) => width}px;
	left: ${({ left }) => left}px;
	background: ${(props) => props.theme.colors.selectedTheme.text.value};
	transition: all 0.3s ease-in-out;
`

export default UnderlineTabs
