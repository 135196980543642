const REVERT_REGEX = /execution reverted: /

export const formatRevert = (revertMsg: string) => {
	if (!revertMsg) return ''
	return revertMsg.replace(REVERT_REGEX, '')
}

export const isUserDeniedError = (message: string | undefined) => {
	if (!message) return false
	return (
		message.toLowerCase().includes('user denied transaction signature') ||
		message.toLowerCase().includes('user rejected') ||
		message.toLowerCase().includes('Network request failed')
	)
}
