import { LocalAccountSigner, WalletClientSigner } from '@aa-sdk/core'
import type { AlchemySmartAccountClient } from '@account-kit/core'
import { arbitrum, base } from '@account-kit/infra'
import {
	type AccountLoupeActions,
	type MultiOwnerModularAccount,
	type MultiOwnerPluginActions,
	type PluginManagerActions,
	SessionKeyPermissionsBuilder,
	SessionKeyPlugin,
	SessionKeyPluginAbi,
	type SessionKeyPluginActions,
	createModularAccountAlchemyClient,
	sessionKeyPluginActions,
} from '@account-kit/smart-contracts'
import { ZERO_BIG_INT } from '@kwenta/sdk/constants'

import { simulatedRequestToTxRequest } from '@kwenta/sdk/utils'
import { StorageKeys } from 'services/storage/storageKeys'
import {
	AbstractAccountAbstraction,
	type SendUserOperationsRequest,
} from 'types/accountAbstraction'
import {
	type Address,
	type Chain,
	type CustomTransport,
	type Hex,
	type PublicClient,
	type WalletClient,
	isAddress,
	isHex,
	zeroHash,
} from 'viem'
import { generatePrivateKey, privateKeyToAccount } from 'viem/accounts'

import { type DateInterval, convertIntervalToSeconds } from 'utils/dates'

type Config = {
	apiKey: string
	chain: Chain
	policyId: string
}

export class AccountAbstraction extends AbstractAccountAbstraction {
	private sdk?: AlchemySmartAccountClient<
		CustomTransport,
		Chain | undefined,
		MultiOwnerModularAccount<WalletClientSigner>,
		MultiOwnerPluginActions<MultiOwnerModularAccount<WalletClientSigner>> &
			PluginManagerActions<MultiOwnerModularAccount<WalletClientSigner>> &
			AccountLoupeActions<MultiOwnerModularAccount<WalletClientSigner>> &
			SessionKeyPluginActions<MultiOwnerModularAccount<WalletClientSigner>>
	>
	public accountAddress?: Address
	protected _chainId?: number

	private config?: Config

	public get chainId() {
		return this._chainId
	}

	private set chainId(chainId: number | undefined) {
		this._chainId = chainId
	}

	private getConfig(chainId: number): Config {
		const supportedChains = {
			[arbitrum.id]: arbitrum,
			[base.id]: base,
		}

		const chain = supportedChains[chainId]

		if (!chain) throw new Error(`Chain ${chainId} not supported for one-click trading`)

		return {
			apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY!,
			chain,
			policyId: process.env.NEXT_PUBLIC_ALCHEMY_POLICY_ID!,
		}
	}

	public async init(client: WalletClient, publicClient: PublicClient) {
		const chainId = publicClient.chain!.id

		this.config = this.getConfig(chainId)

		const sdkClient = await createModularAccountAlchemyClient({
			...this.config,
			signer: new WalletClientSigner(client, 'json-rpc'),
		})

		this.sdk = sdkClient.extend(sessionKeyPluginActions)
		this.chainId = chainId
		this.accountAddress = sdkClient.getAddress()
	}

	public async disconnect() {
		this.sdk = undefined
		this.chainId = undefined
		this.accountAddress = undefined
	}

	public async getUsdcBalance() {
		return ZERO_BIG_INT
	}

	public async createSession(interval: DateInterval | number) {
		if (!this.sdk || !this.accountAddress) {
			throw new Error('SDK not initialized')
		}

		const savedKey = this.storage.get(StorageKeys.SESSION_KEY)
		const key = (savedKey as Hex) ?? generatePrivateKey()
		const account = privateKeyToAccount(key)

		if (!savedKey) {
			this.storage.save(StorageKeys.SESSION_KEY, key)
		}

		const isSessionModuleInstalled = await this.sdk
			.getInstalledPlugins({})
			// This checks using the default address for the chain, but you can always pass in your own plugin address here as an override
			.then((x) => x.includes(SessionKeyPlugin.meta.addresses[this.sdk!.chain!.id]))

		const now = Math.floor(Date.now() / 1000)
		const secInterval = typeof interval === 'number' ? interval : convertIntervalToSeconds(interval)
		const getFutureTimestamp = now + secInterval

		const permissions = new SessionKeyPermissionsBuilder()
			.setContractAccessControlType(2)
			.setTimeRange({
				validFrom: now,
				validUntil: getFutureTimestamp,
			})
			.setNativeTokenSpendLimit({
				spendLimit: BigInt(100),
			})

		if (isSessionModuleInstalled) {
			const activeKeys = await this.sdk.getAccountSessionKeys({
				account: this.sdk.account,
			})

			if (activeKeys.includes(account.address)) {
				const { hash } = await this.sdk.updateSessionKeyPermissions({
					key: account.address,
					permissions: permissions.encode(),
				})

				await this.sdk.waitForUserOperationTransaction({
					hash,
				})
			} else {
				const { hash } = await this.sdk.addSessionKey({
					key: account.address,
					permissions: permissions.encode(),
					tag: zeroHash,
				})

				await this.sdk.waitForUserOperationTransaction({
					hash,
				})
			}
		} else {
			const { hash } = await this.sdk.installSessionKeyPlugin({
				args: [[account.address], [zeroHash], [permissions.encode()]],
			})

			await this.sdk.waitForUserOperationTransaction({
				hash,
			})
		}

		return this.getSessionInfo()
	}

	public async getSessionInfo() {
		if (!this.sdk) throw new Error('SDK not initialized')

		const savedKey = this.storage.get(StorageKeys.SESSION_KEY)
		const key = (savedKey as Hex) ?? generatePrivateKey()
		const account = privateKeyToAccount(key)

		if (!savedKey) {
			this.storage.save(StorageKeys.SESSION_KEY, key)
		}

		const activeKeys = await this.sdk.getAccountSessionKeys({
			account: this.sdk.account,
		})

		if (!activeKeys.includes(account.address)) {
			return undefined
		}

		const sessionInfo = await this.sdk.readContract({
			abi: SessionKeyPluginAbi,
			address: SessionKeyPlugin.meta.addresses[this.sdk!.chain!.id],
			functionName: 'getKeyTimeRange',
			args: [this.sdk.account.address, account.address],
		})

		const session = {
			validAfter: sessionInfo[0],
			validUntil: sessionInfo[1],
		}

		return session
	}

	public async closeAllSessions() {
		if (!this.sdk) throw new Error('SDK not initialized')

		const session = await this.getSessionInfo()

		if (session) {
			const savedKey = this.storage.get(StorageKeys.SESSION_KEY)
			const key = (savedKey as Hex) ?? generatePrivateKey()
			const account = privateKeyToAccount(key)

			const { hash } = await this.sdk.removeSessionKey({
				key: account.address,
			})

			await this.sdk.waitForUserOperationTransaction({
				hash,
			})

			this.storage.remove(StorageKeys.SESSION_KEY)

			return this.getSessionInfo()
		}
	}

	public async sendTransactions(params: SendUserOperationsRequest) {
		if (!this.sdk || !this.chainId) {
			throw new Error('SDK not initialized')
		}

		const { readyTxs, simulateTxs } = params

		if (!(readyTxs?.length || simulateTxs?.length)) {
			throw new Error('No transactions to send')
		}

		const formattedTxs = readyTxs ?? []

		if (simulateTxs) {
			formattedTxs.push(...simulateTxs.map(simulatedRequestToTxRequest))
		}

		const mappedTxs = formattedTxs.map((tx) => {
			if (!isAddress(tx.to) || !isHex(tx.data)) {
				throw new Error('Invalid transaction')
			}

			return {
				value: BigInt(tx.value || 0),
				target: tx.to,
				data: tx.data,
			}
		})

		const savedKey = this.storage.get(StorageKeys.SESSION_KEY)
		const key = (savedKey as Hex) ?? generatePrivateKey()
		const account = privateKeyToAccount(key)
		const config = this.config ?? this.getConfig(this.chainId)

		const sessionKeyClient = (
			await createModularAccountAlchemyClient({
				...config,
				signer: new LocalAccountSigner(account),
				accountAddress: this.accountAddress!,
			})
		).extend(sessionKeyPluginActions)

		const { hash } = await sessionKeyClient.executeWithSessionKey({
			args: [mappedTxs, account.address],
		})

		const transactionHash = await this.sdk.waitForUserOperationTransaction({ hash })

		return transactionHash
	}
}
