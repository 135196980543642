import { type Points, PointsLevel, PointsRank } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

const ZERO_POINTS: Points = {
	total: 0,
	placement: 0,
	user: {
		address: '',
		level: PointsLevel.NPC_I,
		rank: PointsRank.NPC,
	},
}

export const fetchPoints = createAsyncThunk<Points, void, ThunkConfig>(
	'points/fetchPoints',
	async (_, { getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		if (!wallet) {
			return ZERO_POINTS
		}
		try {
			return await sdk.points.getPoints(wallet)
		} catch (error) {
			logError(error)
			throw error
		}
	}
)
