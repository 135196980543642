import { type ComponentType, type FC, type HTMLAttributes, type JSX, memo } from 'react'
import styled, { css } from 'styled-components'

export type FontSize = 'xsmall' | 'small' | 'medium' | 'large'

export type BodyProps = HTMLAttributes<HTMLParagraphElement> & {
	size?: FontSize
	weight?: 'regular' | 'bold' | 'black' | 'semiBold'
	color?:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'positive'
		| 'negative'
		| 'preview'
		| 'sectionHeader'
	type?: 'p' | 'span'
	className?: string
	fontSize?: number
	mono?: boolean
	capitalized?: boolean
	inline?: boolean
	as?: keyof JSX.IntrinsicElements | ComponentType<any>
}

const Body: FC<BodyProps> = memo(
	({
		size = 'medium',
		weight = 'regular',
		color = 'primary',
		type = 'p',
		fontSize,
		mono,
		capitalized,
		inline,
		...props
	}) =>
		type === 'p' ? (
			<StyledBody
				$size={size}
				$weight={weight}
				$fontSize={fontSize}
				$mono={mono}
				$capitalized={capitalized}
				$inline={inline}
				$color={color}
				{...props}
			/>
		) : (
			<StyledBodySpan
				$size={size}
				$weight={weight}
				$fontSize={fontSize}
				$mono={mono}
				$capitalized={capitalized}
				$inline={inline}
				$color={color}
				{...props}
			/>
		)
)

const fontSizeMap = { xsmall: 9, small: 11, medium: 12, large: 14 } as const

const getFont = (weight: NonNullable<BodyProps['weight']>, mono?: boolean) => {
	const fontWeightMap = {
		regular: 400,
		bold: 700,
		black: 900,
		semiBold: 600,
	}

	return css`
		font-weight: ${fontWeightMap[weight]};
		${mono && 'font-feature-settings: "tnum";'}
	`
}

type StyledBodyProps = {
	$size: NonNullable<BodyProps['size']>
	$weight: NonNullable<BodyProps['weight']>
	$color: NonNullable<BodyProps['color']>
	$fontSize?: number
	$mono?: boolean
	$capitalized?: boolean
	$inline?: boolean
}

const BODY_STYLE = css<StyledBodyProps>`
	line-height: 1.2;
	margin: 0;

	${(props) => css`
		color: ${props.theme.colors.selectedTheme.newTheme.text[props.$color]};
		font-size: ${props.$fontSize ?? fontSizeMap[props.$size]}px;
		${getFont(props.$weight, props.$mono)}
		${
			props.$capitalized &&
			css`
			font-variant: all-small-caps;
		`
		}
		${
			props.$inline &&
			css`
			display: inline;
		`
		}
	`}
`

const StyledBody = styled.p<StyledBodyProps>`
	${BODY_STYLE}
`

const StyledBodySpan = styled.span<StyledBodyProps>`
	${BODY_STYLE}
`

export default Body
