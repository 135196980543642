import type { PerpsProvider } from '@kwenta/sdk/types'
import Wei from '@kwenta/wei'

import { notifyError } from 'components/ErrorNotifier'
import logError from 'utils/logError'

import { setQueryStatus } from './futures/reducer'
import { FetchStatus } from './types'

// Redux recommends that values stored in state are serializable
// (Generally for diffing, performance and persistence reasons).
//
// However, we store a lot of values that are Wei instances in
// state, so we are faced with the challenge of having to
// serialize those values, and then deserialize them in
// selectors.
//
// These functions look for these values (recursively for nested ones),
// and replace them with strings (when serializing), or replace the string
// values (read from a map) with a Wei instance (when deserializing).
//
// Currently, this comes at the cost of sacrificing types.

export const serializeWeiObject = (object: object) => {
	return Object.entries(object).reduce((acc, [key, value]) => {
		if (!value) {
			acc[key] = value
		} else if (value instanceof Wei) {
			acc[key] = value.toString()
		} else if (typeof value === 'object') {
			acc[key] = serializeWeiObject(value)
		} else {
			acc[key] = value
		}

		return acc
	}, {} as any)
}

export const handleFetchError = (
	dispatch: any,
	queryKey: string,
	error: Error,
	options?: {
		provider?: PerpsProvider
		notify?: boolean
	}
) => {
	dispatch(
		setQueryStatus({
			key: queryKey,
			status: FetchStatus.Error,
			provider: options?.provider,
			error: error.message,
		})
	)
	logError(error)
	if (options?.notify) {
		let message = `Query failed "${queryKey.replaceAll('_', ' ')}"`
		if (options?.provider) {
			message = `${message} ${options.provider.replaceAll('_', ' ')}`
		}
		notifyError(message, error)
	}
}
