import type { SupportedAccountTypes } from '@account-kit/core'
import {
	useAccount,
	useChain,
	useSigner,
	useSignerStatus,
	useSmartAccountClient,
	useUser,
} from '@account-kit/react'
import type { NetworkId } from '@kwenta/sdk/types'
import { PROVIDER_MAINNET_CHAINS } from 'constants/network'
import { useEffect } from 'react'
import { selectAccountContext } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetWalletAddress } from 'state/wallet/actions'
import { setEmailAddress, setWalletNetworkId } from 'state/wallet/reducer'

const accountType: SupportedAccountTypes = 'MultiOwnerModularAccount'

export const useAlchemySmartAccount = () => {
	const dispatch = useAppDispatch()
	const { provider } = useAppSelector(selectAccountContext)
	const { setChain } = useChain()
	const chain = PROVIDER_MAINNET_CHAINS[provider]
	const { address } = useAccount({ type: accountType })
	const { isConnected } = useSignerStatus()
	const user = useUser()
	const alchemySigner = useSigner()
	const { client: aaWalletClient } = useSmartAccountClient({
		type: accountType,
		policyId: process.env.NEXT_PUBLIC_ALCHEMY_POLICY_ID!,
	})

	useEffect(() => {
		if (!user) return
		dispatch(setEmailAddress(user.email))
	}, [dispatch, user])

	useEffect(() => {
		if (isConnected) {
			setChain({ chain })
			dispatch(resetWalletAddress({ address, selectedType: 'signer' }))
			dispatch(setWalletNetworkId(chain.id as NetworkId))
		}
	}, [address, dispatch, isConnected, chain, setChain])

	return {
		isConnected,
		chain,
		alchemySigner,
		isAaActive: isConnected && !!address,
		aaWalletClient,
		setChain,
	}
}
