import { SupportedNetworkIds } from '@kwenta/sdk/types'
import { fallbackTransport } from '@kwenta/sdk/utils'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
	coinbaseWallet,
	frameWallet,
	injectedWallet,
	metaMaskWallet,
	rabbyWallet,
	rainbowWallet,
	safeWallet,
	tahoWallet,
	walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { supportedChains } from 'state/sdk'
import { http, type Chain } from 'viem'
import {
	arbitrum,
	arbitrumSepolia,
	avalanche,
	base,
	baseSepolia,
	bsc,
	mainnet,
	optimism,
	optimismSepolia,
	polygon,
} from 'viem/chains'
import { createConfig } from 'wagmi'

const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_V2_ID!
const mainnets: readonly [Chain, ...Chain[]] = [mainnet, polygon, avalanche, bsc]
const testnets = [baseSepolia, optimismSepolia, arbitrumSepolia]

const mainnetsTransports = {
	[base.id]: fallbackTransport(supportedChains?.[SupportedNetworkIds.BASE_MAINNET] ?? []),
	[optimism.id]: fallbackTransport(supportedChains?.[SupportedNetworkIds.OPTIMISM_MAINNET] ?? []),
	[arbitrum.id]: fallbackTransport(supportedChains?.[SupportedNetworkIds.ARB_MAINNET] ?? []),
	[mainnet.id]: fallbackTransport(supportedChains?.[SupportedNetworkIds.ETHEREUM_MAINNET] ?? []),
	[polygon.id]: http(),
	[avalanche.id]: http(),
	[bsc.id]: http(),
}

const connectors = connectorsForWallets(
	[
		{
			groupName: 'Wallets',
			wallets: [
				metaMaskWallet,
				rainbowWallet,
				rabbyWallet,
				coinbaseWallet,
				safeWallet,
				tahoWallet,
				frameWallet,
				walletConnectWallet,
				injectedWallet,
			],
		},
	],
	{
		appName: 'Kwenta',
		projectId,
	}
)

const wagmiConfig = createConfig({
	connectors,
	chains: [optimism, base, arbitrum, ...mainnets],
	transports: {
		...mainnetsTransports,
	},
	ssr: true,
})

const wagmiConfigWithTestnet = createConfig({
	connectors,
	chains: [optimism, base, arbitrum, ...mainnets, ...testnets],
	transports: {
		...mainnetsTransports,
		[baseSepolia.id]: fallbackTransport(supportedChains?.[SupportedNetworkIds.BASE_SEPOLIA] ?? []),
		[optimismSepolia.id]: fallbackTransport(
			supportedChains?.[SupportedNetworkIds.OPTIMISM_SEPOLIA] ?? []
		),
		[arbitrumSepolia.id]: fallbackTransport(
			supportedChains?.[SupportedNetworkIds.ARB_SEPOLIA] ?? []
		),
	},
	ssr: true,
})

export { wagmiConfig, wagmiConfigWithTestnet }
