import { createAsyncThunk } from '@reduxjs/toolkit'

import { selectProviderNetwork } from 'state/futures/common/selectors'
import type { ThunkConfig } from 'state/types'

export const checkSynthetixStatus = createAsyncThunk<boolean, void, ThunkConfig>(
	'app/checkSynthetixStatus',
	(_, { getState, extra: { sdk } }) => {
		const chainId = selectProviderNetwork(getState())
		return sdk.system.getSynthetixStatus(chainId)
	}
)
