import { TransactionStatus } from '@kwenta/sdk/types'
import { toWei } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { parseEpochData } from 'queries/staking/utils'
import { selectProviderNetwork, selectSnxPerpsV2Network } from 'state/futures/common/selectors'
import type { RootState } from 'state/store'

const selectSubmittingStakingTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)

export const selectClaimableBalance = createSelector(
	(state: RootState) => state.staking.v2.claimableBalance,
	toWei
)

export const selectStakedKwentaBalance = createSelector(
	(state: RootState) => state.staking.v2.stakedKwentaBalance,
	toWei
)

export const selectEscrowedKwentaBalance = createSelector(
	(state: RootState) => state.staking.v2.escrowedKwentaBalance,
	toWei
)

export const selectStakedEscrowedKwentaBalance = createSelector(
	(state: RootState) => state.staking.v2.stakedEscrowedKwentaBalance,
	toWei
)

export const selectTotalVestable = createSelector(
	(state: RootState) => state.staking.v2.totalVestable,
	wei
)

export const selectKwentaBalance = createSelector(
	(state: RootState) => state.staking.kwentaBalance,
	toWei
)

export const selectFeeShareBalance = createSelector(
	(state: RootState) => state.staking.v2.feeShareBalance,
	toWei
)

export const selectUnstakedEscrowedKwentaBalance = createSelector(
	selectEscrowedKwentaBalance,
	selectStakedEscrowedKwentaBalance,
	(escrowedKwentaBalance, stakedEscrowedKwentaBalance) => {
		return escrowedKwentaBalance.sub(stakedEscrowedKwentaBalance)
	}
)

export const selectStakedResetTime = (state: RootState) => state.staking.stakedResetTime

export const selectEpochData = createSelector(
	selectProviderNetwork,
	(state: RootState) => state.staking.epochPeriod,
	(networkId, epochPeriod) => {
		return Array.from(new Array(epochPeriod + 1), (_, i) => parseEpochData(i, networkId))
	}
)

const selectSubmittingStakinglTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)

export const selectIsStakedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'stake'
		)
	}
)

export const selectIsUnstakedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'unstake'
		)
	}
)

export const selectIsStakedEscrowedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'stake_escrowed'
		)
	}
)

export const selectIsUnstakedEscrowedKwenta = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			(app.transaction?.status === TransactionStatus.Failed ||
				app.transaction?.status === TransactionStatus.Confirmed) &&
			app.transaction?.type === 'unstake_escrowed'
		)
	}
)

export const selectIsApprovingKwenta = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'approve_kwenta'
)

export const selectKwentaRewards = (state: RootState) =>
	state.staking.tradingRewards.claimableRewards.kwenta

export const selectOpReferralRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.claimableRewards.opReferral,
	wei
)

export const selectSnxRewards = (state: RootState) =>
	state.staking.tradingRewards.claimableRewards.snx

export const selectEstimatedOpRewards = createSelector(
	(state: RootState) => state.staking.tradingRewards.estimatedRewards.opReferral,
	wei
)

export const selectFeesPaid = createSelector(
	(state: RootState) => state.staking,
	(staking) => staking.tradingRewards.feesPaid
)

export const selectReferralFeesPaid = createSelector(
	(state: RootState) => state.staking,
	(staking) => staking.tradingRewards.referralFeesPaid
)

export const selectSelectedEpoch = createSelector(
	selectSnxPerpsV2Network,
	(state: RootState) => state.staking.selectedEpoch,
	(state: RootState) => state.staking.epochPeriod,
	(providerNetwork, selectedEpoch, epochPeriod) =>
		parseEpochData(selectedEpoch || epochPeriod, providerNetwork)
)

const selectIsTimeLeftInCooldown = createSelector(
	selectStakedResetTime,
	(stakedResetTime) => stakedResetTime > Date.now() / 1000
)

export const selectCanUnstakeKwenta = createSelector(
	selectStakedKwentaBalance,
	selectIsTimeLeftInCooldown,
	(stakedKwentaBalance, isTimeLeftInCooldown) => stakedKwentaBalance.gt(0) && !isTimeLeftInCooldown
)

export const selectCanUnstakeEscrowedKwenta = createSelector(
	selectStakedEscrowedKwentaBalance,
	selectIsTimeLeftInCooldown,
	(stakedEscrowedKwentaBalance, isTimeLeftInCooldown) => {
		return stakedEscrowedKwentaBalance.gt(0) && !isTimeLeftInCooldown
	}
)

export const selectEpochPeriod = (state: RootState) => state.staking.epochPeriod

export const selectEscrowEntries = (state: RootState) => state.staking.v2.escrowData ?? []

export const selectIsClaimingKwentaRewards = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'claim_kwenta_rewards'
)

export const selectIsClaimingOpRewards = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'claim_op_referral_rewards'
)

export const selectIsTransferring = createSelector(
	selectSubmittingStakingTx,
	(state: RootState) => state.app,
	(submitting, app) =>
		submitting &&
		(app.transaction?.type === 'transfer_escrow_entries' ||
			app.transaction?.type === 'transfer_escrow_entry')
)

export const selectIsGettingReward = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'claim_staking_rewards_v2'
)

export const selectIsVestingEscrowedRewards = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'vest_escrowed_rewards_v2'
)

export const selectIsStakingKwenta = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'stake_kwenta_v2'
)

export const selectIsUnstakingKwenta = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'unstake_kwenta_v2'
)

export const selectIsStakingEscrowedKwenta = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'stake_escrow_v2'
)

export const selectIsUnstakingEscrowedKwenta = createSelector(
	selectSubmittingStakinglTx,
	(state: RootState) => state.app,
	(submitting, app) => submitting && app.transaction?.type === 'unstake_escrow_v2'
)
