import {
	PERIOD_IN_SECONDS,
	SL_TP_MAX_SIZE,
	SL_TP_MAX_SIZE_CROSS_MARGIN,
	SynthAssetKeysV3,
	ZERO_WEI,
} from '@kwenta/sdk/constants'
import {
	type ConditionalOrderV2,
	FuturesMarginType,
	OrderTypeEnum,
	Period,
	PerpsProvider,
	PositionSide,
	TransactionStatus,
} from '@kwenta/sdk/types'
import {
	calculateDesiredFillPrice,
	formatPercent,
	getDisplayAsset,
	notNill,
	stripZeros,
	toSnakeCase,
} from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'
import type { Address } from 'viem'

import type { HistoryTab } from 'sections/dashboard/History/HistoryTabs'
import type { Trade } from 'sections/dashboard/History/TradesTable'
import { selectShowConditionalOrderModal } from 'state/app/selectors'
import { selectBalancesAllProviders } from 'state/balances/selectors'
import { DEFAULT_SUBACCOUNT_COUNTS } from 'state/constants'
import {
	selectActiveCrossMarginPositionsCount,
	selectAllCrossMarginOrderTableItems,
	selectAllSnxV3SLTPOrders,
	selectCMPlaceOrderDisabledReason,
	selectCMPlaceOrderTranslationKey,
	selectCollateralBalancesAllProviders,
	selectCrossMarginAccountTrades,
	selectCrossMarginActivePositions,
	selectCrossMarginAvailableMargin,
	selectCrossMarginMarginInfo,
	selectCrossMarginOrderTableItemsForProvider,
	selectCrossMarginPositions,
	selectGlobalLiquidationsForMarket,
	selectMaxUsdSizeInputCrossMargin,
	selectSelectedSnxV3Position,
	selectSnxV3AccountContext,
	selectSnxV3MaxLeverage,
	selectV3MarginChartData,
	selectV3SelectedMarketInfo,
	selectV3SynthPrices,
} from 'state/futures/snxPerpsV3/selectors'
import { selectPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { FetchStatus } from 'state/types'
import {
	selectAbstractionAddress,
	selectIsSubAccountMode,
	selectSignerWallet,
	selectWallet,
} from 'state/wallet/selectors'
import {
	calculateV3LiqPrice,
	crossMarginProvider,
	formatFuturesPositions,
	providerIsCrossMargin,
	shouldFilterHistoryTable,
	shouldFilterHistoryTableByDate,
	shouldFilterUserInfoTable,
	stopLossValidity,
	takeProfitValidity,
} from 'utils/futures'

import { providerFromChainId } from 'constants/network'
import { truncateTimestamp } from 'utils/dates'
import {
	selectAccountData,
	selectAllNetworks,
	selectEditPositionModalMarket,
	selectEditPositionModalOrderId,
	selectIsolatedMaxLeverage,
	selectLeverageSide,
	selectMarkPrices,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectMarketInfo,
	selectMarketOnchainPrice,
	selectMarkets,
	selectOneClickTradingSelected,
	selectPerpsProvider,
	selectPortfolioChartType,
	selectPriceImpact,
	selectProviderNetworks,
	selectRawTradePanelInputs,
	selectSelectedPortfolioTimeframe,
	selectSlTpModalInputs,
	selectSlTpTradeInputs,
	selectTradePanelInputs,
	selectTradingMode,
	selectUserInfoShowAllMarkets,
} from './common/selectors'
import {
	selectAccountMarginTransfers,
	selectAllIsolatedConditionalOrders,
	selectAllSmartMarginTrades,
	selectAllSnxV2Positions,
	selectAllSnxV2SLTPOrders,
	selectIdleAccountMargin,
	selectIsolatedAvailableMargin,
	selectIsolatedPositionsCount,
	selectIsolatedTradeDisabledReason,
	selectMarginDeltaInputValue,
	selectSMPlaceOrderTranslationKey,
	selectSelectedSnxV2Position,
	selectSmartMarginActivePositions,
	selectSnxV2AccountContext,
	selectSnxV2AccountData,
	selectSnxV2IsolatedBalanceInfo,
} from './isolatedMargin/selectors'
import type { PerpsV2Portfolio } from './isolatedMargin/types'
import type { SnxPerpsV3TradePreview } from './snxPerpsV3/types'
import { type PortfolioValues, type TableFilteredFuturesTabs, TradingModes } from './types'

export const selectQueryStatuses = createSelector(
	(state: RootState) => state.futures,
	(futures) => futures.queryStatuses
)

export const selectTraderPositionHistoryQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_trader_position_history
)

export const selectMarketsQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_markets
)

export const selectPositionTradesQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_trades_for_position
)

export const selectAcknowledgementTerms = (state: RootState) => state.futures.acknowledgementTerms

export const selectHasAcceptedAcknowledgementTerms = createSelector(
	selectSignerWallet,
	selectAcknowledgementTerms,
	(wallet, terms) => wallet && terms[wallet.toLowerCase()]
)

export const selectSelectedTrader = (state: RootState) => state.futures.leaderboard.selectedTrader

export const selectLeaderboardPageSize = (state: RootState) => state.futures.leaderboard.pageSize

export const selectShowHistory = (state: RootState) => !!state.futures.preferences.showHistory

export const selectShowFavorites = (state: RootState) => !!state.futures.preferences.showFavorites

export const selectShowOrderLines = (state: RootState) => !!state.futures.preferences.showOrderLines

export const selectShowPositionLines = (state: RootState) =>
	!!state.futures.preferences.showPositionLines

export const selectShowRealizedPnL = (state: RootState) =>
	!!state.futures.preferences.showRealizedPnL

export const selectShowTestnets = (state: RootState) => !!state.futures.preferences.showTestnets

export const selectShowFunding = (state: RootState) => !!state.futures.preferences.showFunding

export const selectPreviewCount = (state: RootState) => state.futures.previewDebounceCount

export const selectProviderAccount = createSelector(
	selectPerpsProvider,
	selectWallet,
	(state: RootState) => state.futures,
	(provider, wallet, futures) => {
		return wallet ? futures.accounts[provider]?.[wallet]?.account : undefined
	}
)

export const selectIsIsolatedMarginProvider = createSelector(
	selectPerpsProvider,
	(provider) => provider === PerpsProvider.SNX_V2_OP
)

export const selectAccountContexts = createSelector(
	selectSnxV3AccountContext,
	selectSnxV2AccountContext,
	(snxV3AccountContext, snxV2AccountContext) => {
		return {
			[PerpsProvider.SNX_V2_OP]: snxV2AccountContext,
			[PerpsProvider.SNX_V3_BASE]: snxV3AccountContext,
			[PerpsProvider.SNX_V3_ARB]: snxV3AccountContext,
		}
	}
)

export const selectAccountContext = createSelector(
	selectPerpsProvider,
	selectAccountContexts,
	(provider, contexts) => ({
		...contexts[provider],
		provider,
	})
)

export const selectMarketVolumes = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.futures.providerData,
	(type, data) => {
		return data.dailyMarketVolumes[type] ?? {}
	}
)

export const selectMarketVolumesByProvider = (state: RootState) =>
	state.futures.providerData.dailyMarketVolumes

export const selectPosition = createSelector(
	selectSelectedSnxV2Position,
	selectSelectedSnxV3Position,
	selectPerpsProvider,
	(v2, v3, type) => {
		switch (type) {
			case PerpsProvider.SNX_V2_OP:
				return v2
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				return v3
			default:
				return
		}
	}
)

export const selectIsolatedMarginAllowanceValid = createSelector(
	selectSnxV2IsolatedBalanceInfo,
	selectIsolatedAvailableMargin,
	selectTradePanelInputs,
	({ allowance }, availableMargin, { marginDelta }) => {
		// Perennial always needs to be pulled from the wallet even when there is idle margin
		// as idle margin gets withdrawn to the market first
		const marginDeltaWei = wei(marginDelta || 0)
		const marginDeposit = marginDeltaWei.sub(availableMargin)
		return wei(availableMargin).gte(marginDeltaWei) || wei(allowance || 0).gte(marginDeposit)
	}
)

export const selectActivePositions = createSelector(
	selectPerpsProvider,
	selectSmartMarginActivePositions,
	selectCrossMarginActivePositions,
	(selectedProvider, smartPositions, crossPositions) => {
		switch (selectedProvider) {
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				return crossPositions
			case PerpsProvider.SNX_V2_OP:
				return smartPositions
			default:
				return []
		}
	}
)

const selectSelectedMarketActivePosition = createSelector(
	selectMarketAsset,
	selectActivePositions,
	(marketAsset, activePositions) => {
		return activePositions.find(({ asset }) => asset === marketAsset)
	}
)

export const selectUsersPositions = createSelector(
	selectAllSnxV2Positions,
	selectCrossMarginPositions,
	selectPerpsProvider,
	(snxV2Positions, crossMarginPositions, type) => {
		switch (type) {
			case PerpsProvider.SNX_V2_OP:
				return snxV2Positions
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				return crossMarginPositions
			default:
				return []
		}
	}
)

export const selectOpenPositionAsset = createSelector(selectUsersPositions, (userPositions) => {
	const openPositions = userPositions.filter((p) => p.details.status === 'open')
	if (!openPositions.length) return 'sETH'

	return openPositions[0].asset
})

export const selectTotalUnrealizedPnl = createSelector(selectActivePositions, (positions) => {
	return positions
		.reduce((acc, p) => {
			return acc.add(wei(p.details.pnl.uPnl?.pnl ?? ZERO_WEI))
		}, ZERO_WEI)
		.toString()
})

export const selectSubmittingFuturesTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)

export const selectIsSubmittingCrossTransfer = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return (
			(app.transaction?.type === 'deposit_smart_margin' ||
				app.transaction?.type === 'withdraw_isolated_margin') &&
			submitting
		)
	}
)

export const selectIsApprovingCrossDeposit = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return app.transaction?.type === 'approve_cross_margin' && submitting
	}
)

export const selectIsSubmittingIsolatedTransfer = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return (
			(app.transaction?.type === 'deposit_cross_margin' ||
				app.transaction?.type === 'withdraw_cross_margin' ||
				app.transaction?.type === 'permit_margin_engine' ||
				app.transaction?.type === 'approve_cross_margin') &&
			submitting
		)
	}
)

export const selectMaxLeverage = createSelector(
	selectSnxV3MaxLeverage,
	selectIsolatedMaxLeverage,
	selectPerpsProvider,
	(cmMax, isolatedMax, type) => {
		switch (type) {
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				return cmMax
			case PerpsProvider.SNX_V2_OP:
				return isolatedMax
			default:
				return '0'
		}
	}
)

export const selectSelectedInputDenomination = (state: RootState) =>
	state.futures.preferences.selectedInputDenomination

export const selectSelectedInputHours = (state: RootState) =>
	state.futures.preferences.selectedInputHours

export const selectTradePrice = createSelector(
	selectRawTradePanelInputs,
	selectMarketIndexPrice,
	(inputs, indexPrice) => {
		return inputs.orderPrice.price || indexPrice
	}
)

export const selectTradeInputPriceString = createSelector(selectRawTradePanelInputs, (inputs) => {
	return inputs.orderPrice.price ?? ''
})

export const selectTradeSizeInputsDisabled = createSelector(
	selectAccountData,
	selectMarginDeltaInputValue,
	selectPerpsProvider,
	selectCrossMarginAvailableMargin,
	selectTradePanelInputs,
	(accountData, marginDeltaInput, perpsProvider, availableMargin, { orderType, orderPrice }) => {
		if (
			orderType !== OrderTypeEnum.MARKET &&
			(!orderPrice?.price || wei(orderPrice?.price).eq(0) || !!orderPrice?.invalidLabel)
		)
			return true
		if (!accountData?.account) return false
		const remaining = providerIsCrossMargin(perpsProvider)
			? wei(availableMargin || 0)
			: wei(marginDeltaInput || 0)
		return remaining.lte(0)
	}
)

export const selectFuturesPortfolio = createSelector(
	selectSmartMarginActivePositions,
	selectCrossMarginPositions,
	selectIdleAccountMargin,
	(smartPositions, crossPositions, idleMargin) => {
		// TODO: Update this for cross margin
		const crossValue =
			crossPositions.reduce(
				(sum, { details }) => sum.add(details.margin.remainingMargin ?? 0),
				wei(0)
			) ?? wei(0)
		const smartValue =
			smartPositions.reduce(
				(sum, { details }) => sum.add(wei(details.margin.remainingMargin)),
				wei(0)
			) ?? wei(0)
		const totalValue = smartValue.add(crossValue).add(idleMargin)

		return {
			// TODO: should use string when possible to avoid re-renders
			total: totalValue,
			smartMargin: smartValue.add(idleMargin),
			crossMargin: crossValue,
		}
	}
)

const selectSmartMarginTransfers = createSelector(selectSnxV2AccountData, (account) => {
	return account?.marketMarginTransfers ?? []
})

const selectSmartMarginPortfolioValues = createSelector(
	selectAllSmartMarginTrades,
	selectSmartMarginTransfers,
	selectAccountMarginTransfers,
	selectFuturesPortfolio,
	(trades, transfers, accountTransfers, portfolioTotal) => {
		const tradeActions = trades.map(({ account, timestamp, asset, margin }) => ({
			account,
			timestamp,
			asset,
			margin: margin ? Number(margin) : 0,
			size: 0,
			type: 'trade',
		}))

		const transferActions = transfers.map(({ account, timestamp, asset, size }) => ({
			account,
			timestamp,
			asset,
			size,
			margin: 0,
			type: 'market_transfer',
		}))

		const accountTransferActions = accountTransfers.map(({ account, timestamp, asset, size }) => ({
			account,
			timestamp,
			asset,
			size,
			margin: 0,
			type: 'account_transfer',
		}))

		const actions = [...tradeActions, ...transferActions, ...accountTransferActions]
			.filter((action) => !!action)
			.sort((a, b) => {
				if (a.timestamp === b.timestamp) return a.type === 'account_transfer' ? -1 : 1
				return a.timestamp - b.timestamp
			})

		const accountHistory = actions.reduce((acc, action) => {
			if (acc.length === 0) {
				const newTotal = action.size !== 0 ? action.size : action.margin
				const lastAction =
					action.type === 'account_transfer' || !action.asset
						? {
								account: action.account,
								timestamp: action.timestamp,
								assets: {},
								idle: newTotal,
								total: newTotal,
							}
						: {
								account: action.account,
								timestamp: action.timestamp,
								assets: {
									[action.asset]: newTotal,
								},
								idle: 0,
								total: newTotal,
							}

				return [lastAction]
			} else {
				const lastAction = acc[acc.length - 1]
				const newAssets = !action.asset
					? lastAction.assets
					: {
							...lastAction.assets,
							[action.asset]:
								action.size !== 0
									? (lastAction.assets[action.asset] ?? 0) + action.size
									: action.margin,
						}
				const newIdle = !action.asset
					? lastAction.idle + action.size
					: lastAction.idle + action.size * -1

				const newTotal =
					Object.entries(newAssets).reduce((acc, asset) => acc + asset[1], 0) + lastAction.idle

				const newAction = {
					...lastAction,
					timestamp: action.timestamp,
					assets: newAssets,
					idle: newIdle,
					total: newTotal,
				}
				const replacePrevious = newAction.timestamp === lastAction.timestamp

				return [...acc.slice(0, acc.length - (replacePrevious ? 1 : 0)), newAction]
			}
		}, [] as PerpsV2Portfolio[])
		return [
			...accountHistory.map(({ timestamp, total }) => ({ timestamp: timestamp * 1000, total })),
			{
				timestamp: Date.now(),
				total: portfolioTotal.smartMargin.toNumber(),
			},
		]
	}
)

const selectPnlChartData = createSelector(selectAccountData, (accountData) => {
	if (!accountData || accountData.provider === PerpsProvider.SNX_V2_OP) return []
	return accountData.pnlSnapshots.map((s) => ({
		total: wei(s.pnl).toNumber(),
		timestamp: s.timestamp * 1000,
	}))
})

const INTERPOLATION_GAPS: Partial<Record<Period, number>> = {
	[Period.ONE_WEEK]: PERIOD_IN_SECONDS[Period.ONE_HOUR],
	[Period.ONE_MONTH]: PERIOD_IN_SECONDS[Period.ONE_HOUR] * 6,
	[Period.ONE_YEAR]: PERIOD_IN_SECONDS[Period.ONE_DAY],
}

const selectChartData = createSelector(
	selectPerpsProvider,
	selectPortfolioChartType,
	selectPnlChartData,
	selectV3MarginChartData,
	selectSmartMarginPortfolioValues,
	(provider, type, pnl, margin, smartMargin) => {
		if (providerIsCrossMargin(provider)) return type === 'pnl' ? pnl : margin
		if (provider === PerpsProvider.SNX_V2_OP) return smartMargin
		return []
	}
)

export const selectPortfolioChartData = createSelector(
	selectChartData,
	selectSelectedPortfolioTimeframe,
	(chartData, timeframe) => {
		// get the timeframe for interpolation
		const interpolationGap = INTERPOLATION_GAPS[timeframe] ?? PERIOD_IN_SECONDS[Period.ONE_WEEK]

		const minTimestamp = Date.now() - PERIOD_IN_SECONDS[timeframe] * 1000

		const createPortfolioData = (portfolioValues: PortfolioValues[]) => {
			if (portfolioValues.length === 0) return []
			const filteredPortfolioValues = portfolioValues.filter(
				({ timestamp }) => timestamp >= minTimestamp
			)

			const portfolioData: PortfolioValues[] = []
			const totalCount = filteredPortfolioValues.length
				? filteredPortfolioValues.length > 1
					? filteredPortfolioValues.length
					: 2
				: 0
			for (let i = 0; i < totalCount; i++) {
				if (i < totalCount - 1) {
					const index = filteredPortfolioValues.length > 1 ? i : 0
					const currentTimestamp = truncateTimestamp(
						filteredPortfolioValues[index].timestamp,
						interpolationGap * 1000
					)
					const nextTimestamp = truncateTimestamp(
						filteredPortfolioValues[index + 1]?.timestamp ?? minTimestamp,
						interpolationGap * 1000
					)
					const timeDiff = nextTimestamp - currentTimestamp

					if (nextTimestamp !== currentTimestamp) {
						portfolioData.push({
							timestamp: currentTimestamp,
							total: filteredPortfolioValues[index].total,
						})
					}
					if (timeDiff > interpolationGap * 1000) {
						const gapCount = Math.floor(timeDiff / (interpolationGap * 1000)) - 1
						for (let j = 1; j <= gapCount; j++) {
							portfolioData.push({
								timestamp: currentTimestamp + j * interpolationGap * 1000,
								total: filteredPortfolioValues[index].total,
							})
						}
					}
				}
			}

			if (
				portfolioData.length === 1 &&
				portfolioValues[portfolioValues.length - 1]?.timestamp !== portfolioData[0]?.timestamp
			) {
				portfolioData.push(portfolioValues[portfolioValues.length - 1])
			}

			return portfolioData
		}

		return createPortfolioData(chartData)
	}
)

export const selectMaxUsdSizeInput = createSelector(
	selectPerpsProvider,
	selectMaxUsdSizeInputCrossMargin,
	selectMaxLeverage,
	selectMarginDeltaInputValue,
	(futuresType, maxCrossMarginInput, maxLeverage, marginDelta) => {
		switch (futuresType) {
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				return maxCrossMarginInput
			case PerpsProvider.SNX_V2_OP:
				return wei(maxLeverage)
					.mul(marginDelta || 0)
					.toString()
			default:
				return '0'
		}
	}
)

export const selectAvailableOi = createSelector(selectMarketInfo, (marketInfo) => {
	const availableOiUsdShort = marketInfo
		? wei(marketInfo.marketLimitUsd.short).sub(wei(marketInfo.openInterest.shortUSD))
		: wei(0)

	const availableOiUsdLong = marketInfo
		? wei(marketInfo.marketLimitUsd.long).sub(wei(marketInfo.openInterest.longUSD))
		: wei(0)

	const availableOiNativeShort = marketInfo
		? wei(marketInfo.marketLimitNative.short).sub(wei(marketInfo.openInterest.short))
		: wei(0)

	const availableOiNativeLong = marketInfo
		? wei(marketInfo.marketLimitNative.long).sub(wei(marketInfo.openInterest.long))
		: wei(0)

	return {
		short: {
			usd: availableOiUsdShort.toString(),
			native: availableOiNativeShort.toString(),
		},
		long: {
			usd: availableOiUsdLong.toString(),
			native: availableOiNativeLong.toString(),
		},
	}
})

export const selectHistoricalFundingRatePeriod = (state: RootState) =>
	state.futures.preferences.historicalFundingRatePeriod

const selectCMTradesHistoryTableData = createSelector(
	selectCrossMarginAccountTrades,
	(state: RootState) => state.futures.preferences.userInfoTableFilter.trades,
	(trades, tableFilter) => {
		const allTrades = trades.map((trade) => {
			const notionalValue = wei(trade.fillPrice).mul(wei(trade.sizeDelta).abs())

			return {
				market: {
					asset: trade.market.asset,
					marketName: trade.market.marketName,
				},
				pnl: trade.pnl,
				feesPaid: trade.totalFees,
				funding: trade.fundingAccrued,
				netPnl: trade.pnlWithFeesPaid,
				side: trade.side,
				notionalValue: notionalValue.toString(),
				price: trade.fillPrice,
				amount: wei(trade.sizeDelta).abs().toString(),
				timestamp: trade.timestamp * 1000,
				settlementTxHash: trade.settlementTxHash,
				committedTxHash: trade.commitmentTxHash,
				displayAsset: getDisplayAsset(trade?.market?.asset),
				interestCharged: trade.interestCharged,
				orderType: trade.orderType ?? 'Market',
			}
		})

		return allTrades
			.filter((t) => shouldFilterUserInfoTable(new Date(t.timestamp), tableFilter))
			.sort((a, b) => b.timestamp - a.timestamp)
	}
)

export const selectSMTradesHistoryTableData = createSelector(
	selectAllSmartMarginTrades,
	(state: RootState) => state.futures.preferences.userInfoTableFilter.trades,
	(history, tableFilter) => {
		return history
			.map((trade) => {
				const pnl = wei(trade.pnl)
				const feesPaid = wei(trade.totalFees)
				const funding = wei(trade.fundingAccrued)
				const netPnl = pnl.sub(wei(feesPaid)).add(wei(funding))
				const notionalValue = wei(trade?.fillPrice).mul(wei(trade.sizeDelta).abs())

				return {
					side: trade.side,
					price: trade.fillPrice,
					market: {
						asset: trade.market.asset,
						marketName: trade.market.marketName,
					},
					pnl: trade.pnl,
					feesPaid: trade.totalFees,
					funding: trade.fundingAccrued,
					netPnl: netPnl.toString(),
					notionalValue: notionalValue.toString(),
					amount: wei(trade.sizeDelta).abs().toString(),
					timestamp: trade.timestamp * 1000,
					settlementTxHash: trade.txnHash,
					committedTxHash: trade.commitmentTxHash,
					displayAsset: getDisplayAsset(trade.asset),
					orderType: trade.orderType,
					vipTier: (trade.vipTier ?? 0) + 1,
					feeRebate: trade.feeRebate || '0',
					blockNumber: trade.blockNumber ?? 0,
				}
			})
			.filter((t) => shouldFilterUserInfoTable(new Date(t.timestamp), tableFilter))
	}
)

export const selectTradesHistoryTableData = createSelector(
	selectPerpsProvider,
	selectCMTradesHistoryTableData,
	selectSMTradesHistoryTableData,
	(type, cmHistory, smHistory) => {
		switch (type) {
			case PerpsProvider.SNX_V2_OP:
				return smHistory
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				return cmHistory
			default:
				return []
		}
	}
)

export const selectPositionTradesTableData = (state: RootState, positionId: string) => {
	const accountData = selectAccountData(state)
	const markets = selectMarkets(state)
	const trades = get(accountData?.tradesByPosition, positionId, [])

	return trades.reduce<Trade[]>((acc, trade) => {
		const market = markets.find((m) =>
			providerIsCrossMargin(m.provider) ? m.marketId === trade.marketId : m.asset === trade.asset
		)

		if (!market) return acc

		acc.push({
			market: {
				asset: market.asset,
				marketName: market.marketName,
			},
			pnl: trade.pnl,
			netPnl: trade.pnlWithFeesPaid,
			side: trade.side,
			price: trade.fillPrice,
			amount: wei(trade.sizeDelta).abs().toString(),
			timestamp: trade.timestamp * 1000,
			settlementTxHash: trade.settlementTxHash || trade.txnHash,
			committedTxHash: trade.commitmentTxHash,
			displayAsset: getDisplayAsset(market.asset),
			interestCharged: trade.interestCharged,
			orderType: trade.orderType ?? 'Market',
			feesPaid: trade.totalFees,
			funding: trade.fundingAccrued,
		})
		return acc
	}, [])
}

export const selectSelectedDelegationAddress = (state: RootState) =>
	state.futures.delegation.selectedDelegationAddress as Address

export const selectNewNickname = (state: RootState) => state.futures.delegation.newNickname

export const selectAddressBook = createSelector(
	selectSignerWallet,
	(state: RootState) => state.futures.delegation.addressBook,
	(wallet, addressBook) => {
		if (!wallet) return []
		return addressBook[wallet] ?? []
	}
)

export const selectSessionInfo = (state: RootState) => state.futures.oneClickTrading.sessionInfo

export const selectSessionExpiry = (state: RootState) =>
	(!!state.futures.oneClickTrading.sessionInfo &&
		state.futures.oneClickTrading.sessionInfo.validUntil) ||
	0
export const selectAbstractionDelegated = (state: RootState) =>
	state.futures.oneClickTrading.accountDelegated

export const selectShowOneClickTradingOnboard = (state: RootState) =>
	state.app.showModal?.type === 'one_click_trading_onboard'
export const selectDelegationTradingWarning = createSelector(
	selectTradingMode,
	selectIsSubAccountMode,
	(mode, isSubAccountMode) => mode === TradingModes.ONE_CLICK && isSubAccountMode
)

export const selectHistoricalFundingRate = createSelector(
	selectPerpsProvider,
	(state: RootState) => state,
	(type, state) => state.futures.providerData.historicalFundingRates[type] ?? {}
)

export const selectSessionActive = (state: RootState) =>
	state.futures.oneClickTrading.isSessionActive

export const selectSelectedChart = (state: RootState) => state.futures.preferences.selectedChart
const selectAccountDataBySignerWallet = createSelector(
	selectSignerWallet,
	selectPerpsProvider,
	selectProviderNetworks,
	(state: RootState) => state.futures.accounts,
	(wallet, provider, networks, accounts) => {
		const accountData = wallet ? accounts[provider][wallet] : undefined
		return accountData?.network === networks[provider] ? accountData : undefined
	}
)

export const selectDelegatesForAccount = createSelector(
	selectAccountDataBySignerWallet,
	selectSignerWallet,
	selectAbstractionAddress,
	(accountData, signerWallet, oneClickTradingAddress) =>
		accountData?.delegates?.filter(
			({ address }) => ![signerWallet, oneClickTradingAddress].includes(address as Address)
		) ?? []
)

export const selectSubAccountsForAccount = createSelector(
	selectSignerWallet,
	selectPerpsProvider,
	(state: RootState) => state.futures.delegation.subAccountsForWallet,
	(signerWallet, provider, delegates) => {
		if (!signerWallet) return []
		return (
			delegates[provider][signerWallet]?.filter(({ address }) => address !== signerWallet) ?? []
		)
	}
)

export const selectSubAccountsCounts = createSelector(
	selectSignerWallet,
	(state: RootState) => state.futures.delegation.subAccountsForWallet,
	(wallet, delegates) => {
		if (!wallet) return DEFAULT_SUBACCOUNT_COUNTS
		const providers = [PerpsProvider.SNX_V3_BASE, PerpsProvider.SNX_V2_OP, PerpsProvider.SNX_V3_ARB]

		return providers.reduce(
			(counts, provider) => {
				const subAccounts = delegates[provider][wallet] ?? []
				counts[provider] = subAccounts.filter(({ address }) => address !== wallet).length
				return counts
			},
			{ ...DEFAULT_SUBACCOUNT_COUNTS }
		)
	}
)

export const selectEditPositionModalInfo = createSelector(
	selectPerpsProvider,
	selectEditPositionModalMarket,
	selectActivePositions,
	selectPrices,
	selectAccountData,
	selectEditPositionModalOrderId,
	(provider, modalMarketAsset, positions, prices, accountData, orderId) => {
		const position = positions.find((p) => p.market.asset === modalMarketAsset)
		if (!position)
			return {
				position: null,
				market: null,
				marketPrice: '0',
				onChainPrice: '0',
				orderId: null,
			}
		const price = prices[position.market.asset] ?? {
			offChain: wei(0),
			onChain: wei(0),
		}
		const marketPrice =
			!providerIsCrossMargin(position.market.provider) &&
			position?.market?.settings?.skewScale &&
			price.offChain
				? wei(price.offChain).mul(
						wei(position.market.marketSkew).div(position.market.settings.skewScale).add(1)
					)
				: price.offChain ?? wei(0)

		return {
			position,
			market: position.market,
			indexPrice: price.offChain,
			marketPrice: marketPrice.toString(),
			onChainPrice: price.onChain?.toString() ?? '0',
			accountId: accountData?.account,
			provider,
			orderId,
		}
	}
)

export const selectOneClickTradeBtnI18n = createSelector(
	selectOneClickTradingSelected,
	selectAbstractionDelegated,
	(selected, isDelegated) => {
		if (selected) {
			return isDelegated
				? 'futures.one-click-trading.start-session'
				: 'futures.one-click-trading.onboard.activate'
		}
	}
)

export const selectIsFetchingTradePreview = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_trade_preview_trade?.status === FetchStatus.Loading
)

export const selectIsFetchingTradePreviewEdit = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_trade_preview_edit?.status === FetchStatus.Loading
)

export const selectModalSLTPValidity = createSelector(
	selectSlTpModalInputs,
	selectEditPositionModalInfo,
	selectPerpsProvider,
	({ takeProfitPrice, stopLossPrice }, modalInfo, type) => {
		const { position, onChainPrice, marketPrice } = modalInfo

		// Remove onchain price for V3.
		const tpValidity = takeProfitValidity(
			takeProfitPrice,
			position?.details?.side || PositionSide.LONG,
			wei(marketPrice),
			type === PerpsProvider.SNX_V2_OP ? wei(onChainPrice) : undefined
		)
		const slValidity = stopLossValidity(
			stopLossPrice,
			position?.details.price.liquidationPrice
				? wei(position.details.price.liquidationPrice)
				: wei(0),
			position?.details?.side || PositionSide.LONG,
			wei(marketPrice),
			type === PerpsProvider.SNX_V2_OP ? wei(onChainPrice) : undefined
		)

		return {
			takeProfit: tpValidity,
			stopLoss: slValidity,
		}
	}
)

export const selectTradePreview = createSelector(
	selectAccountData,
	selectPerpsProvider,
	selectCrossMarginMarginInfo,
	selectPriceImpact,
	selectMarketInfo,
	(state: RootState) => state.futures.tradePreviews,
	(accountData, type, v3MarginInfo, priceImpact, market, previews) => {
		const preview = previews[type]

		if (!preview || !market) return
		const liqPrice =
			market.marginType === FuturesMarginType.CROSS_MARGIN
				? calculateV3LiqPrice(
						v3MarginInfo,
						preview as SnxPerpsV3TradePreview<string>,
						market
					).toString()
				: preview.liqPrice

		// @ts-ignore no margin on v3
		const margin = wei(preview?.margin ?? 0)
		if (preview) {
			const desiredFillPrice = calculateDesiredFillPrice(
				wei(preview.sizeDelta || 0),
				wei(preview.fillPrice),
				priceImpact
			)

			return {
				...preview,
				margin: margin.toString(),
				liqPrice:
					!accountData?.account && preview.provider === PerpsProvider.SNX_V3_BASE
						? undefined
						: liqPrice,
				desiredFillPrice: desiredFillPrice.toString(),
				leverage: margin?.gt(0) ? wei(preview.notionalValue).div(margin).abs().toString() : '0',
			}
		}
		return null
	}
)

const selectAverageEntryPrice = createSelector(
	selectTradePreview,
	selectSelectedMarketActivePosition,
	(tradePreview, activePosition) => {
		if (activePosition && tradePreview) {
			const { side } = activePosition.details
			const avgEntryPrice = wei(activePosition.details.price.avgEntryPrice ?? ZERO_WEI)
			const size = wei(activePosition.details.size)
			const newSize = wei(tradePreview.newSize)
			const fillPrice = wei(tradePreview.fillPrice)

			const currentSize = side === PositionSide.SHORT ? size.neg() : size

			// If the trade switched sides (long -> short or short -> long), use oracle price
			if (currentSize.mul(newSize).lt(0)) return fillPrice

			// If the trade reduced position size on the same side, average entry remains the same
			if (newSize.abs().lt(size)) return avgEntryPrice

			// If the trade increased position size on the same side, calculate new average
			const existingValue = avgEntryPrice.mul(size)
			const newValue = fillPrice.mul(wei(tradePreview.sizeDelta).abs())
			const totalValue = existingValue.add(newValue)
			return newSize.abs().gt(0) ? totalValue.div(newSize.abs()) : wei(0)
		}
		return null
	}
)

type PositionPreviewData = {
	fillPrice: string
	sizeIsNotZero: boolean
	positionSide: string
	positionSize: string
	leverage: string
	liquidationPrice: string
	avgEntryPrice: string
	notionalValue: string
	showStatus: boolean
}

export const selectPositionPreviewData = createSelector(
	selectTradePreview,
	selectPosition,
	selectAverageEntryPrice,
	(tradePreview, position, modifiedAverage) => {
		if (!position || !tradePreview || tradePreview?.marginType === FuturesMarginType.CROSS_MARGIN) {
			return null
		}

		return {
			fillPrice: tradePreview.fillPrice,
			sizeIsNotZero: tradePreview.newSize && !wei(tradePreview.newSize).eq(0),
			positionSide: wei(tradePreview.newSize).gt(0) ? PositionSide.LONG : PositionSide.SHORT,
			positionSize: wei(tradePreview.newSize).abs().toString(),
			notionalValue: tradePreview.notionalValue,
			leverage: wei(tradePreview.margin).gt(0)
				? wei(tradePreview.notionalValue).div(tradePreview.margin).abs().toString()
				: '0',
			liquidationPrice: tradePreview.liqPrice ?? '0',
			avgEntryPrice: wei(modifiedAverage ?? 0).toString(),
			showStatus: tradePreview.showStatus,
		} as PositionPreviewData
	}
)

export const selectTradePanelSLTPValidity = createSelector(
	selectPerpsProvider,
	selectSlTpTradeInputs,
	selectTradePreview,
	selectTradePrice,
	selectMarketOnchainPrice,
	selectLeverageSide,
	(
		type,
		{ stopLossPrice, takeProfitPrice },
		smTradePreview,
		tradePrice,
		onChainPrice,
		leverageSide
	) => {
		const tradePriceWei = wei(tradePrice || 0)
		if (providerIsCrossMargin(type)) {
			// TODO: Currently cross margin has no liquidation price value in the trade preview to base a max stop loss off of
			const furthestPrice = leverageSide === PositionSide.LONG ? wei(0) : wei(tradePriceWei.mul(10))
			return {
				takeProfit: takeProfitValidity(takeProfitPrice, leverageSide, tradePriceWei),
				stopLoss: {
					invalidLabel: undefined,
					minMaxStopPrice: furthestPrice.toString(),
				},
			}
		}

		const chainPrice =
			type === PerpsProvider.SNX_V2_OP && !onChainPrice.eq(0) ? onChainPrice : undefined

		const tpValidity = takeProfitValidity(takeProfitPrice, leverageSide, tradePriceWei, chainPrice)
		const slValidity = stopLossValidity(
			stopLossPrice,
			wei(smTradePreview?.liqPrice ?? 0),
			leverageSide,
			tradePriceWei,
			chainPrice
		)
		return {
			takeProfit: tpValidity,
			stopLoss: slValidity,
		}
	}
)

export const selectClosePositionPreview = createSelector(selectTradePreview, (preview) => {
	return preview?.action === 'close' ? preview : undefined
})

export const selectEditPositionPreview = createSelector(selectTradePreview, (preview) => {
	return preview?.action === 'edit' ? preview : undefined
})

const selectConditionalOrders = createSelector(
	selectAllCrossMarginOrderTableItems,
	selectAllIsolatedConditionalOrders,
	(crossMarginOrders, isolatedMarginOrders) => {
		return {
			[PerpsProvider.SNX_V2_OP]: isolatedMarginOrders[PerpsProvider.SNX_V2_OP] ?? [],
			[PerpsProvider.SNX_V3_BASE]: crossMarginOrders[PerpsProvider.SNX_V3_BASE] ?? [],
			[PerpsProvider.SNX_V3_ARB]: crossMarginOrders[PerpsProvider.SNX_V3_ARB] ?? [],
		}
	}
)

export const selectConditionalOrderTableItems = createSelector(
	selectPerpsProvider,
	selectUserInfoShowAllMarkets,
	selectConditionalOrders,
	selectMarketAsset,
	(type, userInfoShowAllMarkets, allOrders, currentMarket) => {
		const orders = allOrders[type]
		return userInfoShowAllMarkets ? orders : orders.filter((o) => o.asset === currentMarket)
	}
)

export const selectConditionalOrdersCount = createSelector(
	selectConditionalOrderTableItems,
	(items) => items.length
)

export const selectEditSLTPModalInfo = createSelector(
	selectPerpsProvider,
	selectActivePositions,
	selectMarkets,
	selectShowConditionalOrderModal,
	selectConditionalOrders,
	(provider, positions, markets, modalinfo, orders) => {
		if (!modalinfo) return undefined
		const position = positions.find((p) => p.market.asset === modalinfo?.marketAsset)
		const market = markets.find((m) => m.asset === modalinfo?.marketAsset)

		const order = orders[provider].find((o) => o.id === modalinfo.id)
		return { order, market, position }
	}
)

export const selectProfitCalculatorVisible = (state: RootState) =>
	state.futures.preferences.profitCalculatorVisible

export const selectFavoriteMarkets = (state: RootState) => {
	return state.futures.preferences.favoriteMarkets
}

export const selectUserInfoTableFilter =
	(futuresTab: TableFilteredFuturesTabs) => (state: RootState) =>
		state.futures.preferences.userInfoTableFilter[futuresTab]

export const selectAccountTransfers = createSelector(selectAccountData, (accountData) => {
	return accountData?.accountTransfers ?? []
})

export const selectAccountOrderHistory = createSelector(selectAccountData, (data) => {
	return data?.orderHistory ?? []
})

const selectActiveConditionalOrdersForMarket = createSelector(
	selectPerpsProvider,
	selectMarketAsset,
	selectAccountData,
	selectCrossMarginOrderTableItemsForProvider,
	(type, asset, accountData, v3Orders) => {
		let orders = accountData?.conditionalOrders ?? []
		switch (type) {
			case PerpsProvider.SNX_V3_ARB:
			case PerpsProvider.SNX_V3_BASE:
				return v3Orders.filter((o) => o.status === 'Pending' && o.asset === asset)
			case PerpsProvider.SNX_V2_OP:
				orders = orders as ConditionalOrderV2<string>[]
				return orders.filter((o) => o.asset === asset)
			default:
				return []
		}
	}
)

export const selectConditionalOrdersForMarket = createSelector(
	selectActiveConditionalOrdersForMarket,
	(orders) => {
		return orders.map((order) => {
			return {
				isSlTp: order.isSlTp,
				side: order.side,
				orderTypeDisplay: order.orderTypeDisplay,
				size: order.size,
				targetPrice: order.targetPrice,
			}
		})
	}
)

export const selectDashboardHistoryTableFilter = (historyTab: HistoryTab) => (state: RootState) =>
	state.futures.preferences.dashboardHistoryTableFilter[historyTab]

export const selectPositionsHistoryFilteredTableData = createSelector(
	selectUsersPositions,
	(state: RootState) => state.futures.preferences.dashboardHistoryTableFilter.positions,
	(positions, tableFilter) => {
		return positions
			.filter((p) => shouldFilterHistoryTableByDate(new Date(p.details.openTimestamp), tableFilter))
			.filter((p) =>
				shouldFilterHistoryTable(
					{ asset: p.asset, side: p.details.side, status: p.details.status },
					tableFilter
				)
			)
			.map((p) => {
				// Remove market and SL/TP details from the table as they are not needed and contains data changes with market price
				return {
					...p,
					details: {
						...p.details,
						stopLoss: undefined,
						takeProfit: undefined,
					},
					market: undefined,
				}
			})
	}
)

export const selectTradesHistoryFilteredTableData = createSelector(
	selectTradesHistoryTableData,
	(state: RootState) => state.futures.preferences.dashboardHistoryTableFilter.trades,
	(trades, tableFilter) => {
		return trades
			.filter((t) => shouldFilterHistoryTableByDate(new Date(t.timestamp), tableFilter))
			.filter((t) =>
				shouldFilterHistoryTable(
					{
						asset: t.displayAsset || '',
						side: t.side ?? undefined,
						settlementTxHash: t.settlementTxHash ?? undefined,
						committedTxHash: t.committedTxHash ?? undefined,
					},
					tableFilter
				)
			)
	}
)

export const selectAccountOrderFilteredHistory = createSelector(
	selectAccountOrderHistory,
	(state: RootState) => state.futures.preferences.userInfoTableFilter?.order_history,
	(state: RootState) => state.futures.preferences.dashboardHistoryTableFilter?.order_history,
	(orders, userInfoTableFilter, dashboardTableFilter) => {
		const pathName = window.location.pathname
		return pathName.includes('market')
			? orders
					.filter((o) => shouldFilterUserInfoTable(new Date(o.timestamp), userInfoTableFilter))
					.filter(
						(o) =>
							!userInfoTableFilter?.type ||
							userInfoTableFilter?.type === 'all_orders' ||
							toSnakeCase(o.orderType) === userInfoTableFilter?.type
					)
			: orders
					.filter((o) =>
						shouldFilterHistoryTableByDate(new Date(o.timestamp), dashboardTableFilter)
					)
					.filter((o) =>
						shouldFilterHistoryTable(
							{
								asset: o.displayAsset || '',
								type: o.orderType,
								side: o.side,
								status: o.status,
								settlementTxHash: o.txnHash || '',
							},
							dashboardTableFilter
						)
					)
	}
)

export const selectAccountRecentTransfers = createSelector(selectAccountTransfers, (transfers) =>
	transfers
		.map((tx) => ({
			...tx,
			timestamp: tx.timestamp * 1000,
		}))
		.sort((a, b) => b.timestamp - a.timestamp)
		.slice(0, 5)
)

export const selectAccountFilteredTransfers = createSelector(
	selectAccountTransfers,
	(state: RootState) => state.futures.preferences.userInfoTableFilter?.transaction_history,
	(state: RootState) => state.futures.preferences.dashboardHistoryTableFilter?.transaction_history,
	(transfers, userInfoTableFilter, dashboardTableFilter) => {
		const pathName = window.location.pathname

		const results = pathName.includes('market')
			? transfers
					.filter((tx) => shouldFilterUserInfoTable(new Date(tx.timestamp), userInfoTableFilter))
					.filter(
						(tx) =>
							!userInfoTableFilter?.type ||
							userInfoTableFilter?.type === 'all_transactions' ||
							toSnakeCase(tx.action) === userInfoTableFilter?.type
					)
			: transfers
					.filter((tx) =>
						shouldFilterHistoryTableByDate(new Date(tx.timestamp), dashboardTableFilter)
					)
					.filter((tx) =>
						shouldFilterHistoryTable(
							{ asset: tx.asset, settlementTxHash: tx.txHash, type: tx.action },
							dashboardTableFilter
						)
					)
		return results.map((tx) => {
			return {
				...tx,
				timestamp: tx.timestamp * 1000,
			}
		})
	}
)

export const selectUserInfoOrderHistory = createSelector(
	selectAccountOrderFilteredHistory,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(orderHistory, userInfoShowAllMarkets, currentMarket) => {
		if (userInfoShowAllMarkets) {
			return orderHistory
		}
		return orderHistory.filter((order) => order.displayAsset === getDisplayAsset(currentMarket))
	}
)

export const selectUserInfoTradeHistory = createSelector(
	selectTradesHistoryTableData,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(tradeHistory, userInfoShowAllMarkets, currentMarket) => {
		if (userInfoShowAllMarkets) {
			return tradeHistory
		}
		return tradeHistory.filter((trade) => trade.displayAsset === getDisplayAsset(currentMarket))
	}
)

export const selectPerpsInterestRate = createSelector(selectV3SelectedMarketInfo, (marketInfo) => {
	const rate =
		marketInfo?.marginType === FuturesMarginType.CROSS_MARGIN
			? marketInfo?.settings.interestRate
			: undefined
	const rateWei = wei(rate ?? 0)
	return {
		yearly: rateWei,
		daily: rateWei.gt(0) ? rateWei.div(365) : wei(0),
		hourly: rateWei.gt(0) ? rateWei.div(365 * 24) : wei(0),
	}
})

export const selectLoadingGloablHistory = createSelector(selectQueryStatuses, (statuses) => {
	return statuses.get_global_trades?.status === FetchStatus.Loading
})

export const selectPlaceOrderTranslationKey = createSelector(
	selectPerpsProvider,
	selectCMPlaceOrderTranslationKey,
	selectSMPlaceOrderTranslationKey,
	(perpsProvider, cmTranslationKey, smTranslationKey) => {
		return providerIsCrossMargin(perpsProvider) ? cmTranslationKey : smTranslationKey
	}
)

export const selectPlaceOrderDisabledReason = createSelector(
	selectPerpsProvider,
	selectCMPlaceOrderDisabledReason,
	selectIsolatedTradeDisabledReason,
	(perpsProvider, cmPlaceOrderDisabledReason, smPlaceOrderDisabledReason) => {
		return providerIsCrossMargin(perpsProvider)
			? cmPlaceOrderDisabledReason
			: smPlaceOrderDisabledReason
	}
)

export const selectTradesQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_trade_history?.status
)

export const selectMarginTransfersQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_margin_transfers?.status
)

export const selectOrderHistoryQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses.get_order_history?.status
)

const selectTradeHistoryByPerpsProvider = createSelector(
	selectPerpsProvider,
	selectMarketAsset,
	(state: RootState) => state.futures.providerData.globalTradeHistory,
	(perpsProvider, marketAsset, tradeHistory) => {
		return tradeHistory[perpsProvider][marketAsset] ?? []
	}
)

export const selectGlobalTradeHistoryTableData = createSelector(
	selectPerpsProvider,
	selectTradeHistoryByPerpsProvider,
	selectGlobalLiquidationsForMarket,
	(perpsProvider, globalTrades, crossMarginLiquidations) => {
		if (providerIsCrossMargin(perpsProvider)) {
			// TODO: Paginate cross margin trades
			const trades = globalTrades.map((trade) => {
				return {
					value: Number(trade.fillPrice),
					amount: trade.sizeDelta,
					time: Number(trade.timestamp),
					id: trade.id,
					orderType: 'market',
					account: trade.account,
					ownerAddress: trade.ownerAddress,
				}
			})

			const oldestTrade = trades[trades.length - 1]

			const liquidations = crossMarginLiquidations
				.filter((l) => l.timestamp > oldestTrade?.time)
				.map((liquidation) => {
					return {
						value: Number(liquidation.estimatedPrice),
						amount: liquidation.amount,
						time: Number(liquidation.timestamp),
						id: liquidation.id,
						orderType: 'Liquidation',
						account: liquidation.accountId,
						ownerAddress: liquidation.accountOwner,
					}
				})

			return liquidations.concat(trades).sort((a, b) => b.time - a.time)
		} else {
			const futuresTrades =
				globalTrades.length > 0
					? globalTrades
							.flat()
							.filter(notNill)
							.map((trade) => {
								const size =
									trade.side === 'long' ? wei(trade.sizeDelta) : wei(trade.sizeDelta).abs().neg()
								return {
									value: Number(trade.fillPrice),
									amount: size.toString(),
									time: Number(trade.timestamp),
									id: trade.txnHash,
									orderType: trade.orderType,
									account: trade.account,
									ownerAddress: trade.account,
								}
							})
							.filter((trade) => wei(trade.amount).abs().gt(0.000001))
					: []
			return [...new Set(futuresTrades)]
		}
	}
)

export const selectAvailableMargin = createSelector(
	selectPerpsProvider,
	selectCrossMarginAvailableMargin,
	selectIsolatedAvailableMargin,
	(perpsProvider, v3Margin, v2Margin) => {
		return providerIsCrossMargin(perpsProvider) ? v3Margin : v2Margin
	}
)

export const selectActivePositionsCount = createSelector(
	selectPerpsProvider,
	selectActiveCrossMarginPositionsCount,
	selectIsolatedPositionsCount,
	(perpsProvider, cmCounts, isolatedCounts) => {
		return { ...isolatedCounts, ...cmCounts }[perpsProvider]
	}
)

const selectAllSLTPOrders = createSelector(
	selectAllSnxV2SLTPOrders,
	selectAllSnxV3SLTPOrders,
	(snxV2Orders, snxV3Orders) => {
		return {
			[PerpsProvider.SNX_V2_OP]: snxV2Orders,
			[PerpsProvider.SNX_V3_BASE]: snxV3Orders,
			[PerpsProvider.SNX_V3_ARB]: snxV3Orders,
		}
	}
)

export const selectSLTPModalInfo = createSelector(
	selectPerpsProvider,
	selectAllSLTPOrders,
	selectEditPositionModalInfo,
	(provider, allOrders, modalInfo) => {
		if (!modalInfo?.market)
			return {
				takeProfitPrice: '',
				stopLossPrice: '',
			}

		const orders = allOrders[modalInfo.market.provider]

		let isFullSize = !modalInfo.orderId
		const sl = orders.find(
			(o) =>
				o.asset === modalInfo.market?.asset &&
				o.orderTypeDisplay === 'Stop Loss' &&
				((!modalInfo.orderId && o.isSlTp) || (!!modalInfo.orderId && o.id === modalInfo.orderId))
		)
		const tp = orders.find(
			(o) =>
				o.asset === modalInfo.market?.asset &&
				o.orderTypeDisplay === 'Take Profit' &&
				((!modalInfo.orderId && o.isSlTp) || (!!modalInfo.orderId && o.id === modalInfo.orderId))
		)
		switch (provider) {
			case PerpsProvider.SNX_V2_OP:
				isFullSize =
					((sl?.size ? wei(sl?.size).abs().eq(SL_TP_MAX_SIZE) : undefined) ||
						(tp?.size ? wei(tp?.size).abs().eq(SL_TP_MAX_SIZE) : undefined)) ??
					isFullSize
				break
			case PerpsProvider.SNX_V3_BASE:
				isFullSize =
					(wei(sl?.size ?? 0)
						.abs()
						.eq(SL_TP_MAX_SIZE_CROSS_MARGIN) ||
						wei(tp?.size ?? 0)
							.abs()
							.eq(SL_TP_MAX_SIZE_CROSS_MARGIN)) ??
					isFullSize
				break
		}

		return {
			takeProfitPrice: tp?.targetPrice ? stripZeros(tp.targetPrice.toString()) : '',
			stopLossPrice: sl?.targetPrice ? stripZeros(sl.targetPrice.toString()) : '',
			position: modalInfo.position,
			market: modalInfo.market,
			isFullSize,
			size: sl?.size ?? tp?.size ?? '0',
			order: sl ?? tp,
		}
	}
)

export const selectLeaderboardTableData = createSelector(
	selectPerpsProvider,
	selectSelectedTrader,
	selectMarkets,
	selectAllSLTPOrders,
	selectMarkPrices,
	(state: RootState) => state.futures.leaderboard.selectedTraderPositionHistory,
	(provider, trader, markets, orders, prices, traderPositions) => {
		const positions = traderPositions[provider]
		const traderAddress = trader?.trader
		if (!traderAddress || !positions) return []
		return formatFuturesPositions(positions[traderAddress] ?? [], markets, orders[provider], prices)
	}
)

export const selectProviderBalanceChanges = createSelector(
	selectPortfolioChartData,
	(chartData) => {
		const snapshots = [...chartData].reverse()

		if (!snapshots || snapshots.length === 0) {
			return { balanceChange: 0, changeLabel: formatPercent(0, { maxDecimals: 2 }) }
		}

		const initialTotal = snapshots[0].total
		const finalTotal = snapshots[snapshots.length - 1].total
		const balanceChange = finalTotal - initialTotal

		const balanceChangePercent = initialTotal !== 0 ? balanceChange / initialTotal : 0

		return {
			balanceChange,
			balanceChangePercent,
			changeLabel: formatPercent(balanceChangePercent ?? ZERO_WEI, { maxDecimals: 2 }),
		}
	}
)

export const selectWalletBalanceByProvider = createSelector(
	selectBalancesAllProviders,
	selectPerpsProvider,
	selectAllNetworks,
	selectV3SynthPrices,
	(walletBalances, perpsProvider, isAllNetworksSelected, prices) => {
		if (!walletBalances) return []
		return Object.entries(walletBalances).flatMap(([chainId, assets]) => {
			return Object.entries(assets)
				.flatMap(([asset, { balance }]) => {
					const price = asset === 'ETH' ? prices[SynthAssetKeysV3.sETH] ?? ZERO_WEI : wei(1)
					const balanceInUSD = wei(balance).mul(price)
					return {
						asset,
						totalBalance: balanceInUSD.toString(),
						availableBalance: balanceInUSD.toString(),
						providers: providerFromChainId(Number(chainId)),
						account: 'account.account-section.connected-wallet',
					}
				})
				.filter(({ availableBalance }) => Number(availableBalance) > 0.01)
				.filter(({ providers }) => isAllNetworksSelected || providers.includes(perpsProvider))
		})
	}
)

export const selectCollateralBalanceByProvider = createSelector(
	selectCollateralBalancesAllProviders,
	selectPerpsProvider,
	selectAllNetworks,
	(collateralBalances, perpsProvider, isAllNetworksSelected) => {
		return collateralBalances.filter(
			({ providers }) =>
				isAllNetworksSelected ||
				providers.includes(perpsProvider as PerpsProvider.SNX_V3_ARB | PerpsProvider.SNX_V3_BASE)
		)
	}
)

export const selectProviderBalance = createSelector(
	selectAllNetworks,
	selectPerpsProvider,
	(isAllNetworksSelected, perpsProvider) => {
		return [
			{
				name: 'header.nav.perps-provider-switcher.snx_v3',
				providers: [PerpsProvider.SNX_V3_BASE, PerpsProvider.SNX_V3_ARB],
				active: isAllNetworksSelected || !!crossMarginProvider(perpsProvider),
			},
			{
				name: 'header.nav.perps-provider-switcher.snx_v2',
				providers: [PerpsProvider.SNX_V2_OP],
				active: isAllNetworksSelected || perpsProvider === PerpsProvider.SNX_V2_OP,
			},
		]
	}
)

export const selectProviderTotalBalances = createSelector(
	selectWalletBalanceByProvider,
	selectCollateralBalanceByProvider,
	(walletBalances, collateralBalances) => {
		return [...walletBalances, ...collateralBalances]
			.reduce((acc, { totalBalance }) => wei(acc).add(wei(totalBalance) ?? ZERO_WEI), ZERO_WEI)
			.toString()
	}
)
