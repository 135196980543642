import { EPOCH_START } from '@kwenta/sdk/constants'
import type { NetworkId } from '@kwenta/sdk/types'
export type TradingRewardProps = {
	period: number | string
	start?: number
	end?: number
}

const WEEK = 604800

function getEpochDetails(networkId: number, epoch: number) {
	const currentEpochTime = EPOCH_START[networkId]
		? EPOCH_START[networkId] + WEEK * epoch
		: EPOCH_START[10]
	const epochEndTime = currentEpochTime + WEEK
	return { epochStart: currentEpochTime, epochEnd: epochEndTime }
}

export const parseEpochData = (index: number, networkId?: NetworkId) => {
	const { epochStart, epochEnd } = getEpochDetails(networkId ?? 10, index)
	const label = `Epoch ${index}`
	return { period: index, start: epochStart, end: epochEnd, label }
}
