import { Language } from 'translations/constants'
import { ONE_MINUTE_IN_MS } from 'utils/dates'

// app defaults
export const DEFAULT_LANGUAGE: Language = Language.EN

// ui defaults
export const DEFAULT_CRYPTO_DECIMALS = 4
export const DEFAULT_FIAT_DECIMALS = 2
export const DEFAULT_TOKEN_DECIMALS = 18
export const DEFAULT_USDC_DECIMALS = 6

// for Trading History
export const MAX_TOTAL_TRADES: number = 9999

// for mobile leaderboard
export const DEFAULT_LEADERBOARD_ROWS = 20

// for perps v2
export const DEFAULT_DELAYED_EXECUTION_BUFFER = 40
export const DEFAULT_DELAYED_CANCEL_BUFFER = 10

// for trade notification
export const DEFAULT_NOTIFICATION_TIMEOUT = 90

export const ORDERS_WARNING_DISABLED = true

export const MARGIN_ENGINE_ENABLED = true
export const V3_CONDITIONAL_ORDERS_ENABLED = true

// One click trading
export const IS_ONE_CLICK_TRADING_ENABLED = true

export const POLL_FREQUENCY_INTERVALS = {
	active: {
		high: ONE_MINUTE_IN_MS / 4,
		mid: ONE_MINUTE_IN_MS / 2,
		low: ONE_MINUTE_IN_MS,
	},
	inactive: {
		high: ONE_MINUTE_IN_MS,
		mid: ONE_MINUTE_IN_MS * 5,
		low: ONE_MINUTE_IN_MS * 10,
	},
}
export const DEPOSIT_LEFT_FOR_GAS = 0.002
